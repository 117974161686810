import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { convertMarketName } from 'utils/formatters/marketName'
import { getMarketName, MarketKeyByAsset } from '@dextoroprotocol/sdk/utils'

import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { selectMarketAsset } from 'state/futures/common/selectors'
import useClickOutside from 'hooks/useClickOutside'

import BaseModal from 'components/BaseModal'
import { FlexDivRowCentered } from 'components/layout/flex'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import { FuturesPositionTablePositionActive } from 'types/futures'
import media from 'styles/media'

import AmountContainer from './AmountContainer'
import PositionMetadata from './PositionMetadata'
import ShareModalButton, { SharePositionModalButtonContainer } from './ShareModalButton'

import PNLGraphicPNG from 'assets/png/pnl-graphic.png'
import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'
import MobilePNLGraphicPNG from 'assets/png/mobile-pnl-graphic.png'

type SharePositionTableProps = {
	sharePositionTable: FuturesPositionTablePositionActive[]
	setShowShareModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SharePositionTable: React.FC<SharePositionTableProps> = (props) => {
	const { t } = useTranslation()
	const defaultAsset = useAppSelector(selectMarketAsset)
	const currentTheme = useAppSelector(selectCurrentTheme)

	const sharePositionArray = props.sharePositionTable.map((position) => {
		const marketAsset = position?.market.asset ?? defaultAsset
		const marketName = convertMarketName(getMarketName(marketAsset))
		return {
			...position,
			marketAsset: marketAsset,
			marketName: marketName,
		}
	})

	const [expand, setExpand] = React.useState(false)
	const [sharePosition, setSharePosition] = React.useState(sharePositionArray[0])
	const { ref } = useClickOutside(() => setExpand(false))

	const handleClick = () => setExpand(!expand)

	return (
		<StyledBaseModal
			isOpen
			title={t('futures.modals.share.title')}
			onDismiss={() => props.setShowShareModal(false)}
		>
			<ModalWindow>
				<PNLGraphic id="pnl-graphic">
					<PNLImageFrame>
						<DesktopOnlyView>
							<PNLImage src={PNLGraphicPNG} aria-label="pnl-graphic" />
						</DesktopOnlyView>
						<MobileOrTabletView>
							<PNLImage src={MobilePNLGraphicPNG} aria-label="pnl-graphic" />
						</MobileOrTabletView>
					</PNLImageFrame>
					<AmountContainer position={sharePosition} />
					<PositionMetadata position={sharePosition} />
				</PNLGraphic>
				<OptionContainer>
					<OptionDiv ref={ref}>
						<OptionButton active={expand} currentTheme={currentTheme} onClick={handleClick}>
							<CurrencyIcon
								width={24}
								height={24}
								currencyKey={MarketKeyByAsset[sharePosition.marketAsset]}
							/>
							{sharePosition.marketName} <StyledDropdownArrow expanded={expand} />
						</OptionButton>
						{expand && (
							<List>
								{sharePositionArray.map((position) => (
									<Item
										currentTheme={currentTheme}
										onClick={() => {
											setSharePosition(position)
											handleClick()
										}}
									>
										<CurrencyIcon
											width={24}
											height={24}
											currencyKey={MarketKeyByAsset[position.marketAsset]}
										/>
										{position.marketName}
									</Item>
								))}
							</List>
						)}
					</OptionDiv>
					<ShareModalButton position={sharePosition} />
				</OptionContainer>
			</ModalWindow>
		</StyledBaseModal>
	)
}

export default SharePositionTable

const StyledBaseModal = styled(BaseModal)``

const ModalWindow = styled.div`
	padding: 0px 25px;
	font-feature-settings: 'zero' 0;
	${media.greaterThan('md') &&
	media.lessThan('lgNew')`
		padding: 0px;
	`}
	${media.lessThan('md')`
		padding: 0px 12px;
	`}
`

const PNLGraphic = styled.div`
	position: relative;
`

const PNLImageFrame = styled.div`
	position: relative;
`

const PNLImage = styled.img`
	width: 100%;
	height: auto;
	border: none;
	display: block;
	position: relative;
`

const OptionContainer = styled(FlexDivRowCentered)`
	@media (max-width: 900px) {
		justify-content: center;
		gap: 1vw;
		${SharePositionModalButtonContainer} {
			button {
				height: 34px;
				padding: 6px 8px;
			}
		}
	}
`

const Option = styled.div`
	display: flex;
	align-items: center;
	font-size: 13px;
	margin: 16px 0;
`

const OptionTitle = styled.span`
	font-size: 15px;
	margin-right: 12px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

const OptionDiv = styled.div`
	position: relative;
	display: inline-block;
`

const OptionButton = styled.button<{ active?: boolean; currentTheme?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	white-space: nowrap;
	user-select: none;
	min-height: 32px;
	height: 32px;
	width: auto;
	padding: 6px;
	${media.lessThan('md')`
    padding: 6px 3px;
  `}
	font-size: 12px;
	cursor: pointer;
	border-radius: 8px;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.primary};
	background: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.fill
			: props.theme.colors.selectedTheme.button.fillHover};
	border: ${(props) =>
		props.currentTheme === 'dark' ? 'none' : props.theme.colors.selectedTheme.border};
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
`

const StyledDropdownArrow = styled(DropdownArrow)<{ expanded?: boolean }>`
	margin: 0 8px;
	transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(360deg)')};
	transition: all 0.15s ease-in-out 0s !important;
`

const List = styled.div`
	display: flex;
	overflow: auto;
	flex-direction: column-reverse;
	border-radius: 8px;
	position: absolute;
	max-height: 168px;
	width: 112px;
	bottom: 42px;
	z-index: 1;
`

const Item = styled.button<{ currentTheme?: string }>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 6px 12px 6px 6px;
	white-space: nowrap;
	user-select: none;
	min-height: 32px;
	height: 32px;
	width: auto;
	border: none;
	cursor: pointer;
	font-size: 12px;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
`
