import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { PositionSide } from '@dextoroprotocol/sdk/types'
import { formatDollars, formatNumber, getMarketName } from '@dextoroprotocol/sdk/utils'
import { toPng } from 'html-to-image'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FuturesPositionTablePositionActive } from 'types/futures'

import XIcon from 'assets/svg/social/x.svg'
import Button from 'components/Button'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import { blockExplorer } from 'containers/Connector/Connector'

import { convertMarketName } from 'utils/formatters/marketName'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

function getTwitterText(
	side: PositionSide,
	marketName: string,
	leverage: string,
	pnlPct: string,
	dollarEntry: string,
	dollarCurrent: string
) {
	return encodeURIComponent(`Here is the PnL report of my position on @DexToro_com:

${side === 'long' ? 'Long' : 'Short'} $${convertMarketName(marketName)} ${leverage}: ${pnlPct}
from ${dollarEntry} to ${dollarCurrent}

https://dextoro.com/`)
}

function downloadPng(dataUrl: string) {
	const link = document.createElement('a')

	link.download = 'my-pnl-on-dextoro.png'
	link.href = dataUrl
	link.pathname = 'assets/png/' + link.download
	link.click()
}

type ShareModalButtonProps = {
	position: FuturesPositionTablePositionActive
}

const ShareModalButton: FC<ShareModalButtonProps> = ({ position }) => {
	const { t } = useTranslation()
	const currentTheme = useAppSelector(selectCurrentTheme)

	const handleDownloadImage = async () => {
		let node = document.getElementById('pnl-graphic')

		if (node) {
			const dataUrl = await toPng(node, { cacheBust: true })
			downloadPng(dataUrl)
		}
	}

	const handleTweet = () => {
		const side = position?.activePosition.side === 'long' ? PositionSide.LONG : PositionSide.SHORT
		const marketName = convertMarketName(getMarketName(position.market.asset!))
		const leverage = formatNumber(position?.activePosition.leverage ?? ZERO_WEI) + 'x'
		const pnlPct = `+${position?.activePosition.pnlPct.mul(100).toNumber().toFixed(2)}%`

		const avgEntryPrice = position.activePosition.details?.avgEntryPrice
			? formatNumber(position.activePosition.details?.avgEntryPrice)
			: ''
		const dollarEntry = formatDollars(avgEntryPrice ?? ZERO_WEI, { suggestDecimals: true })
		const dollarCurrent = formatNumber(position.activePosition.lastPrice ?? ZERO_WEI)
		const text = getTwitterText(side, marketName, leverage, pnlPct, dollarEntry, dollarCurrent)
		window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank')
	}

	const handleShare = () => {
		window.open(
			blockExplorer.txLink(position?.activePosition?.details?.transactionHash ?? ''),
			'_blank',
			'noopener noreferrer'
		)
	}

	return (
		<>
			<DesktopOnlyView>
				<SharePositionModalButtonContainer>
					<StyledButton
						variant="primary"
						onClick={handleDownloadImage}
						size="xsmall"
						disabled={false}
						currentTheme={currentTheme}
					>
						{t('futures.modals.share.buttons.download')}
					</StyledButton>
					<StyledButton
						variant="primary"
						onClick={handleTweet}
						size="xsmall"
						disabled={false}
						currentTheme={currentTheme}
					>
						<span>{t('futures.modals.share.buttons.twitter')}</span>
						<StyledXIcon />
					</StyledButton>
					<StyledButton
						variant="primary"
						onClick={handleShare}
						size="xsmall"
						disabled={false}
						currentTheme={currentTheme}
					>
						{t('futures.modals.share.buttons.blockExplorer')}
					</StyledButton>
				</SharePositionModalButtonContainer>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<SharePositionModalButtonContainer>
					<StyledButton
						variant="secondary"
						onClick={handleDownloadImage}
						size="xsmall"
						disabled={false}
						currentTheme={currentTheme}
					>
						{t('futures.modals.share.buttons.download')}
					</StyledButton>
					<StyledButton
						variant="primary"
						onClick={handleTweet}
						size="xsmall"
						disabled={false}
						currentTheme={currentTheme}
					>
						<InnerButtonContainer>
							<span>{t('futures.modals.share.buttons.twitter')}</span>
							<StyledXIcon />
						</InnerButtonContainer>
					</StyledButton>
					<StyledButton
						variant="primary"
						onClick={handleShare}
						size="xsmall"
						disabled={false}
						currentTheme={currentTheme}
					>
						{t('futures.modals.share.buttons.blockExplorer')}
					</StyledButton>
				</SharePositionModalButtonContainer>
			</MobileOrTabletView>
		</>
	)
}

export const SharePositionModalButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 25px 0;
	gap: 1vw;
`

const InnerButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1vw;
`

const StyledXIcon = styled(XIcon)`
	width: 0.5rem;
	height: 0.6rem;
	path {
		fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}
`

const StyledButton = styled(Button)<{ currentTheme: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.lightWhite};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	gap: 4px;
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

export default ShareModalButton
