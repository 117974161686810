import styled from 'styled-components'

import { PriceChange } from 'state/prices/types'

import { Body } from './Text'

export const getColorFromPriceChange = (change?: PriceChange) => {
	return change === 'up' ? 'green' : change === 'down' ? 'red' : 'white'
}

const ColoredPrice = styled(Body)<{ priceChange?: PriceChange }>`
	font-family: ${(props) => props.theme.fonts.mono};
	font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '13px')};
	color: ${(props) => {
		const color = getColorFromPriceChange(props.priceChange)
		return color !== 'white'
			? props.theme.colors.selectedTheme[color]
			: props.theme.colors.selectedTheme.text.value
	}};
`

export default ColoredPrice
